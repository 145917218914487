<template>
  <v-layout
      align-center
      justify-center
      :style="{'background-image': `url(${require('@/assets/images/wallpaper.png')}`}"
      style="height: 100vh"
      class="pa-5">
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card width="40vw" min-width="750">
      <v-toolbar
          flat
          color="primary"
          dark
      >
        <v-toolbar-title>
          <v-layout align-center class="fill-height pr-2" style="width: calc(100% - 50px); min-width: 750px">
            User Profile
            <v-spacer/>
            <v-btn icon small color="secondary" @click="$router.push({ path: '/app' })" class="mr-5">
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </v-layout>
        </v-toolbar-title>
      </v-toolbar>
      <v-tabs vertical slider-color="secondary" v-model="tab">
        <v-tab style="font-size: 13px">
          <v-icon left>
            mdi-account
          </v-icon>
          User info
        </v-tab>
        <v-tab style="font-size: 13px">
          <v-icon left>
            mdi-lock
          </v-icon>
          Password
        </v-tab>

        <v-tab-item>
          <v-layout column style="background-color: var(--v-accent-base)" class="pa-5">
            <div style="flex: none;">
              <v-layout class="fill-height" justify-center align-center column>
                <div style="width: 130px; height: 130px; position: relative">
                  <img
                      :src="imgBase64 ? imgBase64 : currentUser.avatar ? transformUrl(currentUser.avatar) : require('@/assets/images/default-avatar.jpg')"
                      height="100%" width="100%"
                      style="border-radius: 50%; border: 2px solid var(--v-primary-base); padding: 4px" alt="icon"/>
                  <v-btn
                      @click="uploadDialog = true"
                      style="position: absolute; bottom: 10px; right: 5px"
                      x-small
                      color="secondary"
                      fab
                      class="elevation-0">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </div>
                <h3 class="mt-3">{{ currentUser.email }}</h3>
              </v-layout>
            </div>
            <v-form v-model="valid" ref="formData" class="d-flex flex-column fill-height">
              <v-layout column class="fill-height py-2">
                <v-layout class="fill-height">
                  <div class="flex" style="flex: 1;">
                    <div style="height: 60px; width: 100%; flex: none">
                      <v-layout class="fill-height" align-center>
                        <h2>User information</h2>
                      </v-layout>
                    </div>
                    <span style="font-size: 14px; font-weight: bold;">Name</span>
                    <div style="width: 100%;" class="pt-1">
                      <v-text-field
                          light
                          class="input-login"
                          height="50"
                          dense
                          background-color="#00000042"
                          solo
                          flat
                          v-model="profile.name"
                          style="color: black !important;"
                          :rules="[rules.requiredUserName]"
                      >
                      </v-text-field>
                    </div>
                  </div>
                </v-layout>
              </v-layout>
            </v-form>
            <div style="flex: none; height: 65px;">
              <v-layout class="fill-height" justify-center align-center>
                <v-btn color="secondary" width="180" class="ml-2" @click="saveProfile">
                  <v-icon class="mr-1">mdi-content-save</v-icon>
                  Save profile
                </v-btn>
              </v-layout>
            </div>
          </v-layout>
        </v-tab-item>
        <v-tab-item>
          <v-layout column style="background-color: var(--v-accent-base)" class="pa-5">
            <div style="flex: none;">
              <v-layout class="fill-height" justify-center align-center column>
                <div style="width: 130px; height: 130px; position: relative">
                  <img
                      :src="imgBase64 ? imgBase64 : currentUser.avatar ? transformUrl(currentUser.avatar) : require('@/assets/images/default-avatar.jpg')"
                      height="100%" width="100%"
                      style="border-radius: 50%; border: 2px solid var(--v-primary-base); padding: 4px" alt="icon"/>
                  <v-btn
                      @click="uploadDialog = true"
                      style="position: absolute; bottom: 10px; right: 5px"
                      x-small
                      color="secondary"
                      fab
                      class="elevation-0">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </div>
                <h3 class="mt-3">{{ currentUser.email }}</h3>
              </v-layout>
            </div>
            <v-form v-model="valid" ref="passwordFrom" class="d-flex flex-column fill-height">
              <v-layout column class="fill-height py-5">
                <v-layout class="fill-height">
                  <div class="flex" style="flex: 1">
                    <v-card flat style="background-color: transparent">
                      <span style="font-size: 14px; font-weight: bold;">Password</span>
                      <div style="width: 100%;" class="pt-1">
                        <v-text-field
                            dark
                            class="input-login"
                            height="50"
                            dense
                            background-color="#00000042"
                            solo
                            flat
                            v-model="password"
                            :rules="tab === 1 ?  [rules.requiredPassword] : []"
                            :type="isShowPassword ? 'text' : 'password'"
                            :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="isShowPassword = !isShowPassword">
                        </v-text-field>
                      </div>
                      <span style="font-size: 14px; font-weight: bold;">Confirm Password</span>
                      <div style="width: 100%;" class="pt-1">
                        <v-text-field
                            dark
                            class="input-login"
                            height="50"
                            dense
                            background-color="#00000042"
                            solo
                            flat
                            v-model="confirmPassword"
                            :rules="tab === 1 ?  [rules.requiredConfirmPassword] : []"
                            :type="isShowPassword ? 'text' : 'password'"
                            :disabled="!this.password"
                            :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="isShowPassword = !isShowPassword">
                        </v-text-field>
                      </div>
                    </v-card>
                  </div>
                </v-layout>
              </v-layout>
            </v-form>
            <div style="flex: none; height: 65px;">
              <v-layout class="fill-height" justify-center align-center>
                <v-btn color="secondary" width="180" class="ml-2" @click="saveProfile">
                  <v-icon class="mr-1">mdi-content-save</v-icon>
                  Save profile
                </v-btn>
              </v-layout>
            </div>
          </v-layout>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-dialog
        v-model="uploadDialog"
        width="500"
        persistent
    >
      <v-card>
        <v-card-text>
          <v-layout style="height: 70px" align-center justify-center>
            <h2>Upload image</h2>
            <v-btn
                @click="uploadDialog = false"
                icon
                small
                style="position: absolute; top: 10px; right: 10px">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>
          <UploadForm @change="updateAvatar" accept=".jpg, .jpeg, .png"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import {mapState} from "@/store/ults"
import {checkToken, updateProfile} from "@/backend"
import Store from "@/store"
import UploadForm from "@/components/UploadForm"
import config from "@/config.json"

export default {
  name: "Profile",
  components: {UploadForm},
  data() {
    return {
      tab: null,
      items: [
        'web', 'shopping', 'videos', 'images', 'news',
      ],
      file: undefined,
      imgBase64: undefined,
      uploadDialog: false,
      isChangePassWord: false,
      loading: false,
      valid: false,
      popupForgot: false,
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredPassword: value => (!!value && !!value.trim() && /^(?=.*[@,!,$,#,%])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value)) || 'Password is invalid',
        requiredConfirmPassword: value => (!!value && !!value.trim() && value.trim() === this.password.trim()) || 'Password is invalid'
      },
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /[0-9]{8,}$/.test(v) || 'Phone number must be more than 7 characters and include only numbers'
      ],
      confirmPassword: undefined,
      password: undefined,
      isShowPassword: false,
      profile: {}
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  mounted() {
    this.profile = JSON.parse(JSON.stringify(this.currentUser))
  },
  watch: {
    // uploadDialog (val) {
    //   if (val) {
    //     this.file = undefined
    //     this.imgBase64 = undefined
    //   }
    // }
  },
  methods: {
    toBase64() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },

    async updateAvatar(avatar) {
      this.file = avatar
      this.imgBase64 = await this.toBase64()
      this.uploadDialog = false
    },
    async saveProfile() {
      try {
        if (this.tab === 1) this.$refs.passwordFrom.validate()
        else this.$refs.formData.validate()
        if (!this.valid) return
        this.loading = true
        if (this.file) {
          if (this.file.size > 25000000) return (this.$store.commit('message/SHOW_ERROR', 'Image size <= 25MB'))
          const formData = new FormData()
          formData.append('avatar', this.file)
          formData.append('name', this.profile.name)
          if (this.tab === 1) {
            this.password ? formData.append('password', this.password) : ''
            this.confirmPassword ? formData.append('password_confirmation', this.confirmPassword) : ''
          }
          await updateProfile(formData)
        } else {
          await updateProfile({
            name: this.profile.name,
            password: this.password ? this.password : undefined,
            password_confirmation: this.confirmPassword ? this.confirmPassword : undefined
          })
        }
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
      } finally {
        await this.getMe()
        this.loading = false
      }
    },
    transformUrl(url) {
      return config.host + url
    },
    async getMe() {
      try {
        this.loading = true
        let res = await checkToken()
        Store.commit('auth/SET_USER', res.data)
      } catch (e) {
      } finally {
        this.profile = JSON.parse(JSON.stringify(this.currentUser))
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.user-info {
  background: rgba(63, 62, 62, 0.65);
  max-width: 800px;
  height: 650px;
  border: 1px solid lightgray;
  border-radius: 8px;
  overflow-y: auto;
  border: 1px solid rgba(137, 63, 242, 0.1);
  box-shadow: inset 0 0 3px var(--v-tab-base);
}

/deep/
.input-login input {
  color: #a8a8a8 !important;
}

/deep/
.input-login label {
  color: #a8a8a8 !important;
}

/deep/
.v-tab--active {
  color: var(--v-secondary-base) !important;
}
</style>
